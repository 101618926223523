import * as React from "react";
import Layout from "../../components/Layout";

const ResumePage = () => {
  return (
    <Layout pageTitle="Resume">
      <div data-section="resume" className="resume main-section">
        <div className="main-section-inner text-container">
          <div className="resume-inner">
            <h2>Resume</h2>

            <h3>Objective</h3>

            <p>
              I seek a user interface engineering position at a forward-thinking
              creative agency or product-focused company.
            </p>

            <h3>Talent</h3>

            <ul>
              <li>
                Multidisciplinary proficiencies; designed, engineered and
                shipped full-stack web apps using NextJS/React.
              </li>
              <li>
                Specialized workflow allowing for quick iteration & prototyping.
              </li>
              <li>
                Hybrid ability to create and translate effectively between
                design & engineering teams.
              </li>
              <li>
                Experience with developing tools & structure to standardize
                components across a given design system.
              </li>
              <li>
                Broad history of work spanning from print design to web
                application development.
              </li>
            </ul>

            <h3>Experience</h3>

            <h4>
              <div className="name">
                Extreme Arts &amp; Sciences - Senior UI Developer
              </div>
              <div className="date">2016 - 2023</div>
            </h4>
            <p>
              At Extreme Arts & Sciences, I was a team lead in the experience
              division - working with a small group of junior developers while
              remaining an individual contributor across multiple fronts. Using
              custom user interface frameworks I authored, I delivered projects
              for customers including Microsoft, GoDaddy, and NVIDIA. I
              broadened my skillset to include back-end development and
              front-end javascript libraries, while remaining focused on
              delivering quality, accessible, user experiences.
            </p>

            <h4>
              <div className="name">Solitude Creative - UI Developer</div>
              <div className="date">2014 - 2016</div>
            </h4>
            <p>
              At Solitude Creative, I was the primary interface designer and
              resident CSS expert. Solitude immediately realized the value of
              bringing someone onto the team with a deep understanding of
              code-based layout design. Solitude is a nimble, fast-paced
              creative agency, constantly juggling multiple projects at once,
              all of them with tight deadlines. My hybrid role and approach to
              iterative prototyping in user interface design helped us meet
              these deadlines and produce solutions that our clients could walk
              away happy from.
            </p>

            <h4>
              <div className="name">DreamTime Studios - Web Designer</div>
              <div className="date">2012 - 2014</div>
            </h4>
            <p>
              Dreamtime is a small creative agency based out of Spokane,
              Washington. I was the first employee and primary assistant to the
              business owner, taking their website design mockups and
              implementing in CSS, as well as expanding on the given designs to
              create mobile and tablet layouts. My expertise in responsive web
              design and working with CMS systems started at Dreamtime Studios.
            </p>

            <h4>
              <div className="name">Signature Genomics - Print Designer</div>
              <div className="date">2010 - 2012</div>
            </h4>
            <p>
              Signature Genomics is a genetics testing company specializing in
              microarray testing. At Signature I worked across print and
              interactive media. My duties included designing and branding new
              collateral, as well as updating the markup and visual design of
              Signature Genomics’ website and proprietary web application:
              Genoglyphix. I began as an intern and then was hired onto the team
              shortly afterwards.
            </p>

            <p>
              <a href="https://alextebbs.com/assets/img/resume/alex-tebbs-resume.pdf">
                Download my resume as a PDF
              </a>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ResumePage;
